/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "edanmer-google-maps-react";
import { map, isEmpty, groupBy, keys, find, difference } from "lodash";
import usePrevious from "../../hooks/usePrevious";
import { isArrayEqual, currencyFormat } from "../../constants/Utils";

import styleModule from "./styles.module.css";

const CustomMap = ({ google, items, center, onMarkerClick }) => {
	// const [bounds, setBounds] = useState({});
	const [cities, setCities] = useState([]);
	const [markers, setMarkers] = useState([]);
	const [selectedProperty, setSelectedProperty] = useState({});
	const [activeWindows, setActiveWindows] = useState(false);
	const [activeMarker, setActiveMarker] = useState({});
	const [zoom, setZoom] = useState(13);
	const prevItems = usePrevious(items);

	useEffect(() => {
		if (!isEmpty(items)) {
			const cities = map(keys(groupBy(items, "cityId")), (item) => {
				return {
					cityId: parseInt(item),
					color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
				};
			});
			setCities(cities);
		}
	}, []);

	useEffect(() => {
		if (!isArrayEqual(prevItems, items)) {
			const oldCitiesIds = map(cities, (item) => item.cityId);
			const newCitiesIds = map(keys(groupBy(items, "cityId")), (item) => parseInt(item));
			const newCities = map(difference(newCitiesIds, oldCitiesIds), (item) => {
				return {
					cityId: item,
					color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
				};
			});
			const mixedCities = [...cities, ...newCities];
			// let newBounds = new google.maps.LatLngBounds();
			// newBounds.extend({ lat: 25.761681, lng: -80.191788 });
			const markers = map(items, (item, index) => {
				// if (item.latitud !== "0" && item.longitud !== "0") {
				//   newBounds.extend({ lat: parseFloat(item.latitud), lng: parseFloat(item.longitud) });
				// }
				return (
					<Marker
						key={item.id}
						index={index}
						onMouseover={onHoverMarker}
						onClick={onMarkerClick}
						position={{ lat: item.latitud, lng: item.longitud }}
						icon={{
							fillColor: find(mixedCities, { cityId: item.cityId })?.color || "#FF0000",
							fillOpacity: 1,
							strokeWeight: 1,
							strokeColor: "#000000",
							scale: 2,
							anchor: new google.maps.Point(0, 0),
							path: `M16.417 10.283A7.917 7.917 0 1 1 8.5 2.366a7.916 7.916 0 0 1 7.917 7.917zm-4.844 1.754a2.249 2.249 0 0 0-.556-1.477l-.001-.002a3.02 3.02 0 0 0-.835-.665l-.003-.002a3.498 3.498 0 0 0-.866-.313H9.31a3.78 3.78 0 0 0-.795-.083 2.849 2.849 0 0 1-.475-.037 1.8 1.8 0 0 1-.494-.158l-.002-.001a1.17 1.17 0 0 1-.371-.298L7.172 9a.733.733 0 0 1-.175-.44.749.749 0 0 1 .421-.63 2.157 2.157 0 0 1 1.11-.297 2.283 2.283 0 0 1 .391.066l.049.01a2.479 2.479 0 0 1 .473.166 1.33 1.33 0 0 1 .381.261.792.792 0 1 0 1.118-1.12 2.902 2.902 0 0 0-.85-.585 3.996 3.996 0 0 0-.785-.268h-.001l-.008-.002v-.786a.792.792 0 1 0-1.583 0v.763a3.557 3.557 0 0 0-1.14.454 2.328 2.328 0 0 0-1.159 1.967 2.296 2.296 0 0 0 .529 1.44 2.724 2.724 0 0 0 .894.717 3.342 3.342 0 0 0 .942.305 4.398 4.398 0 0 0 .736.059 2.202 2.202 0 0 1 .46.046 1.927 1.927 0 0 1 .467.168 1.431 1.431 0 0 1 .382.308.674.674 0 0 1 .165.436c0 .097 0 .324-.385.573a2.182 2.182 0 0 1-1.132.314 3.515 3.515 0 0 1-.494-.06 2.381 2.381 0 0 1-.459-.148h-.001a.953.953 0 0 1-.356-.274.792.792 0 1 0-1.197 1.037 2.516 2.516 0 0 0 .967.708 3.799 3.799 0 0 0 .774.237h.007v.783a.792.792 0 1 0 1.583 0v-.79a3.581 3.581 0 0 0 1.17-.479 2.215 2.215 0 0 0 1.107-1.9z`,
						}}
					/>
				);
			});
			// setters
			setCities(mixedCities);
			setActiveWindows(false);
			setActiveMarker({});
			setMarkers(markers);
			// setBounds(newBounds);
		}
	}, [items]);

	const onReady = (_, map) => {
		map.setOptions({
			mapTypeId: "roadmap",
			center,
		});
	};

	const onZoomChanged = (e, { zoom }) => {
		setZoom(zoom);
	};

	const onMapClicked = () => {
		setActiveWindows(false);
		setActiveMarker({});
		setSelectedProperty({});
	};

	const onHoverMarker = (props, marker, e) => {
		setActiveWindows(true);
		setActiveMarker(marker);
		setSelectedProperty(items[props?.index]);
	};

	return (
		<Map
			google={google}
			containerStyle={styles.containerStyle}
			style={styles.container}
			styles={styles.hide}
			minZoom={6}
			zoom={zoom}
			center={center}
			// bounds={bounds}
			maxZoom={16}
			mapType={"roadmap"}
			onReady={onReady}
			onZoomChanged={onZoomChanged}
			onClick={onMapClicked}>
			{markers}
			<InfoWindow marker={activeMarker} visible={activeWindows} onClose={onMapClicked}>
				<div className={styleModule.container}>
					<h1 className={styleModule.title}>{currencyFormat(selectedProperty?.amount)}</h1>
					<h3 className={styleModule.text}>{String(selectedProperty?.address).toUpperCase()}</h3>
				</div>
			</InfoWindow>
		</Map>
	);
};

const styles = {
	wrapper: {
		flex: 1,
	},
	container: {
		position: "relative",
		top: "0.5%",
		left: "0.5%",
		width: "99%",
		height: "99%",
	},
	containerStyle: {
		position: "relative",
		width: "100%",
		height: "100%",
	},
	hide: [
		{
			featureType: "transit",
			stylers: [{ visibility: "off" }],
		},
	],
};

export default GoogleApiWrapper(
	{
		apiKey: "AIzaSyD3y4gjRi9rL3JRthvlj59N0GqKUK2geT0",
		v: "3.30",
	},
	styleModule.wrapper
)(CustomMap);
