import React, { PureComponent } from "react";
import { Form, Button } from "semantic-ui-react";

class FileInput extends PureComponent {
	fileInputRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			file: null,
		};
	}

	fileChange = (e) => {
		const { onChange } = this.props;
		const file = e.target.files[0];

		this.setState({ file });
		if (onChange) {
			onChange(file);
		}
	};

	renderButton = (_size) => {
		const { label, size, onChange, ...rest } = this.props;
		return (
			<Form>
				<Form.Field>
					<Button
						{...rest}
						style={{
							boxShadow: "none",
							marginTop: "1em",
							textAlign: "center",
						}}
						size={_size ? _size : size}
						content={label ? label : "Upload File"}
						icon="upload"
						onClick={() => this.fileInputRef.current.click()}
					/>
					<input ref={this.fileInputRef} type="file" hidden onChange={this.fileChange} />
				</Form.Field>
			</Form>
		);
	};

	render() {
		const { file } = this.state;
		return (
			<>
				{!file && this.renderButton()}
				{file && (
					<>
						<p style={{ margin: "1em 0 0 0" }}>{file.name}</p>
						<div>{this.renderButton("mini")}</div>
					</>
				)}
			</>
		);
	}
}

export default FileInput;
