/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Input, Container, Checkbox } from "semantic-ui-react";
import { usePlacesWidget } from "react-google-autocomplete";
import { isEmpty } from "lodash";

//Redux
import { connect } from "react-redux";
// import genericAction from "../../../redux/actions/generic";

//Styles
import styles from "./styles.module.css";
// import Text from "../../../components/Text";

const SearchBar = ({ dispatch, handleFilter, handleToggle }) => {
	const [address, setAddress] = useState("");
	const [isTaxDeeds, setIsTaxDeeds] = useState(false);
	// const [lienTypes, setLienTypes] = useState([]);
	// const [lienType, setLienType] = useState("");
	// const [fineMin, setFineMin] = useState("");
	// const [fineMax, setFineMax] = useState("");
	const { ref } = usePlacesWidget({
		apiKey: "AIzaSyD3y4gjRi9rL3JRthvlj59N0GqKUK2geT0",
		options: {
			fields: ["formatted_address"],
			strictBounds: false,
			types: ["address"],
			componentRestrictions: { country: "us" },
		},
		onPlaceSelected: (place) => {
			setAddress(place.formatted_address);
			ref.current.focus();
		},
	});

	// useEffect(() => {
	//   (async () => {
	//     const promise = dispatch(genericAction("GET", "/lienTypeCity"));
	//     const [response] = await Promise.all([promise]);
	//     if (!isEmpty(response)) {
	//       const mappedTypes = map(response, (item) => {
	//         return { text: item.type, value: item.id };
	//       });
	//       setLienTypes(mappedTypes);
	//     }
	//   })();
	// }, []);

	const handleEnter = (e) => {
		const { key } = e;
		if (key === "Enter") {
			handleFilter(address);
		}
	};

	const handleCheckbox = () => {
		setIsTaxDeeds((prev) => !prev);
		handleToggle(!isTaxDeeds);
	};

	const handleSearch = () => {
		handleFilter(address);
	};

	const handleClear = () => {
		setAddress("");
		handleFilter("");
	};

	return (
		<div className={styles.container}>
			<Container className={styles.row}>
				<div className={styles.toggleContainer}>
					<Checkbox
						className={styles.toggle}
						toggle
						checked={isTaxDeeds}
						label={isTaxDeeds ? "Tax Deeds" : "Liens"}
						onChange={handleCheckbox}
					/>
				</div>
				<Input
					className={styles.input}
					placeholder={"Address"}
					value={address}
					onChange={(e, { value }) => setAddress(value)}
					onKeyPress={handleEnter}>
					<input ref={ref} className={!isEmpty(address) ? styles.underlyingInput : ""} />
					{!isEmpty(address) && <Button tabIndex={-1} className={styles.clearIconLast} icon={"cancel"} onClick={handleClear} />}
				</Input>
				{/* <Select
          className={styles.input2}
          clearable
          options={lienTypes}
          placeholder={"Lien Type"}
          value={lienType}
          onChange={(e, { value }) => setLienType(value)}
        />
        <Text>Fine Range: </Text>
        <Input
          className={styles.input3}
          type="number"
          placeholder={"Min"}
          value={fineMin}
          onChange={(e, { value }) => setFineMin(value)}
        />
        <Input
          className={styles.input3}
          type="number"
          placeholder={"Max"}
          value={fineMax}
          onChange={(e, { value }) => setFineMax(value)}
        /> */}
				<Button className={styles.button} onClick={handleSearch}>
					{"Find"}
				</Button>
			</Container>
		</div>
	);
};

export default connect()(SearchBar);
