import React, { useState, useRef } from "react";
import { Icon, Button, Ref, Popup, Form } from "semantic-ui-react";
import Joi from "joi";
import { get, map, isEmpty } from "lodash";

//Components
import Text from "../Text";
import Input from "../Input";

//Utils
import { getTimeLabel } from "../../constants/Utils";

//Styles
import styles from "./styles.module.css";

const MessageCard = ({ item, status, isSamePlatform, sentByMe, handleAccept, handleCounter, allowCounter }) => {
	const [amount, setAmount] = useState("0");
	const [counterVisible, setCounterVisible] = useState(false);
	const [errors, setErrors] = useState({ amount: "" });
	const counterRef = useRef(null);
	const amountRef = useRef(null);

	const schema = Joi.object().keys({
		amount: Joi.number().greater(0).required().messages({
			"any.required": "Amount is required",
			"number.base": "Amount is required",
			"number.greater": "Amount must be greater than 0",
		}),
	});

	const openCounterModal = () => {
		setCounterVisible(true);
		setAmount("");
		amountRef?.current?.focus();
	};

	const closeCounterModal = () => {
		setCounterVisible(false);
		setAmount("");
	};

	const handleInput = (e) => {
		const { value } = e.target;
		setAmount(value);
	};

	const handleEnter = (e) => {
		const { key } = e;
		if (key === "Enter") {
			e.preventDefault();
			handleSubmit();
		}
	};

	const handleSubmit = () => {
		const validation = schema.validate({ amount }, { abortEarly: false });
		let errors = { amount: "" };
		if (validation.error) {
			const details = validation.error.details;
			map(details, (item) => {
				errors = { ...errors, [item.context.key]: item.message };
			});
			setErrors(errors);
		} else {
			handleCounter(item.id, item.lienId, amount);
			setErrors(errors);
			setAmount("");
			setCounterVisible(false);
		}
	};

	const handleDownload = () => {
		window.open(item.url, "_blank");
	};

	const renderIcon = (type) => {
		switch (type) {
			case "Message":
				return (
					<div className={`${styles.iconContainer} ${styles.messageColor}`}>
						<Icon className={`${styles.icon} ${styles.messageIconColor}`} name={"mail outline"} />
					</div>
				);
			case "Negotiation":
				return (
					<div className={`${styles.iconContainer} ${styles.negotiationColor}`}>
						<Icon className={`${styles.icon} ${styles.negotiationIconColor}`} name={"handshake outline"} />
					</div>
				);
			case "Note":
				return (
					<div className={`${styles.iconContainer} ${styles.noteColor}`}>
						<Icon className={`${styles.icon} ${styles.noteIconColor}`} name={"file outline"} />
					</div>
				);
			case "System":
				return (
					<div className={`${styles.iconContainer} ${styles.systemColor}`}>
						<Icon className={`${styles.icon} ${styles.systemIconColor}`} name={"laptop"} />
					</div>
				);
			default:
				break;
		}
	};

	const renderHeader = (item) => {
		if (item.type === "System") {
			return (
				<div className={styles.systemHeader}>
					<div className={styles.headerContainer}>
						<Text className={styles.headerText}>{item.text}</Text>
					</div>
					<div className={styles.timestampContainer}>
						<Text className={styles.timeText}>{getTimeLabel(item.timestamp)}</Text>
					</div>
				</div>
			);
		}
		return (
			<div className={styles.header}>
				<div className={styles.headerContainer}>
					<Text className={styles.headerText}>{get(item, "sender.name", "")}</Text>
				</div>
				<div className={styles.timestampContainer}>
					<Text className={styles.timeText}>{getTimeLabel(item.timestamp)}</Text>
				</div>
			</div>
		);
	};

	const renderContent = (item, status, isSamePlatform, sentByMe, handleAccept) => {
		switch (item.type) {
			case "Message": {
				return (
					<>
						{!isEmpty(item.url) && (
							<Button className={styles.downloadButton} color="green" icon="download" content="Download Attachment" onClick={handleDownload} />
						)}
						<Text>{item.text}</Text>
					</>
				);
			}
			case "Negotiation":
				const numericAmount = item.text.split("$");
				const acceptAmount = String(numericAmount[1]).replaceAll(",", "");
				return (
					<>
						<Text>{item.text}</Text>
						{status !== "Ready" && !sentByMe && !isSamePlatform && !item.replied && (
							<div className={styles.buttonRow}>
								<Button className={styles.button} color={"blue"} onClick={() => handleAccept(item.id, item.lienId, acceptAmount)}>
									<Icon name={"handshake outline"} className={styles.buttonIcon} />
									Accept
								</Button>
								{allowCounter && (
									<Ref innerRef={counterRef}>
										<Button className={styles.button} color={"blue"} onClick={openCounterModal}>
											<Icon name={"redo"} className={styles.buttonIcon} />
											Counter
										</Button>
									</Ref>
								)}
								<Popup
									className={styles.counterPopup}
									context={counterRef}
									open={counterVisible}
									onClose={closeCounterModal}
									position={"left center"}
									basic
									wide>
									<Form className={styles.counterModal}>
										<Text className={styles.counterText}>{"Enter an amount"}</Text>
										<Input
											className={styles.counterInput}
											ref={amountRef}
											icon={"dollar"}
											type={"number"}
											min={"0"}
											name={"amount"}
											value={amount}
											onChange={handleInput}
											onKeyPress={handleEnter}
											error={
												!isEmpty(errors.amount) && {
													content: errors.amount,
													pointing: "above",
												}
											}
										/>
										<Button className={styles.counterButton} fluid icon={"handshake outline"} content={"Submit Counter"} onClick={handleSubmit} />
									</Form>
								</Popup>
							</div>
						)}
					</>
				);
			case "Note":
				return null;
			default:
				break;
		}
	};

	if (item.type === "Note") return null;
	return (
		<div className={styles.item}>
			{renderIcon(item.type)}
			<div className={styles.contentContainer}>
				{renderHeader(item)}
				{item.type !== "System" && <div className={styles.content}>{renderContent(item, status, isSamePlatform, sentByMe, handleAccept)}</div>}
			</div>
		</div>
	);
};

export default MessageCard;
