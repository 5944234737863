import moment from "moment";
import { isEmpty, isEqual, xorWith } from "lodash";

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

export function currencyFormat(num, includeSymbol = true) {
	const symbol = includeSymbol ? "$" : "";
	if (num !== undefined && typeof num === "string") {
		const newNum = parseFloat(num);
		return `${symbol}${newNum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
	} else if (num === null) {
		return "";
	} else if (num !== undefined && num !== null) return `${symbol}${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
}

export function camelCaseToText(str) {
	return str.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
}

export function dateFormat(date) {
	if (isEmpty(date)) return "";
	return moment(date).format("MM/DD/YYYY");
}

export function getTimeLabel(time, timeOnly = false, dateOnly = false) {
	const propTime = moment(time);
	if (timeOnly) return propTime.format("h:mm A");
	if (dateOnly) return propTime.format("MM/DD/YYYY");
	return propTime.format("MM/DD/YYYY h:mm A");
}

export function getDateLabel(date) {
	if (!date) return "";
	return moment(date).format("MM/DD/YYYY");
}

export function getAddress(string) {
	const split = string.split(",");
	return split[0];
}

export function phoneFormat(string) {
	if (string) {
		const cleanString = string.replace(/[^\d]/g, "");
		if (cleanString.length === 10) {
			return cleanString.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
		} else if (cleanString.length === 11) {
			return cleanString.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 $2-$3-$4");
		}
		return string;
	}
	return "";
}

export function translateDate(date) {
	const split = date.split("-");
	const yyyy = split[0] * 1;
	const mm = split[1] * 1;
	const dd = split[2] * 1;
	return `${mm}/${dd}/${yyyy}`;
}

export function parseDate(str) {
	const ymd = str.split("-");
	return new Date(ymd[0], ymd[1] - 1, ymd[2]);
}

export function dateDiff(first, second) {
	return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export function guidGenerator() {
	const S4 = function () {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	};
	return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
}
