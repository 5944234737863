import React from "react";
import { get, isEmpty } from "lodash";

//Components
import Text from "../Text";

//Utils
import { currencyFormat, getDateLabel } from "../../constants/Utils";
import { calculateInterest } from "../../utils/business";

//Styles
import styles from "./styles.module.css";

const LienAccordionItem = ({ mitigationItem, lienItem }) => {
	const relation = get(mitigationItem, "relation", "");
	const showRelationExtra = !isEmpty(relation) && relation !== "Owner";
	return (
		<div className={styles.item}>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Address:</Text>
				<Text className={styles.text}>{get(mitigationItem, "property.address", "")}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Folio:</Text>
				<Text className={styles.text}>{get(mitigationItem, "property.folio", "")}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Legal Description:</Text>
				<Text className={styles.text}>{get(mitigationItem, "property.legalDescription", "")}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Relation to Property:</Text>
				<Text className={styles.text}>{get(mitigationItem, "relation", "")}</Text>
			</div>
			{showRelationExtra && (
				<div className={styles.textRow}>
					<Text className={styles.boldText}>{relation}'s Name:</Text>
					<Text className={styles.text}>{get(mitigationItem, "relationName", "")}</Text>
				</div>
			)}
			{showRelationExtra && (
				<div className={styles.textRow}>
					<Text className={styles.boldText}>{relation}'s Phone:</Text>
					<Text className={styles.text}>{get(mitigationItem, "relationPhone", "")}</Text>
				</div>
			)}
			{showRelationExtra && (
				<div className={styles.textRow}>
					<Text className={styles.boldText}>{relation}'s Address:</Text>
					<Text className={styles.text}>{get(mitigationItem, "relationAddress", "")}</Text>
				</div>
			)}
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Status:</Text>
				<Text className={styles.text}>{get(lienItem, "status", "")}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Lien Type:</Text>
				<Text className={styles.text}>{get(lienItem, "type", "")}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Case Number:</Text>
				<Text className={styles.text}>{get(lienItem, "caseNumber", "")}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Date Violation:</Text>
				<Text className={styles.text}>{getDateLabel(get(lienItem, "dateOfViolation", ""))}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Date Fine Began:</Text>
				<Text className={styles.text}>{getDateLabel(get(lienItem, "dateOfFineBegan", ""))}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Original Amount:</Text>
				<Text className={styles.text}>{currencyFormat(get(lienItem, "originalFine", ""))}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Date Recorded:</Text>
				<Text className={styles.text}>{getDateLabel(get(lienItem, "dateRecorded", ""))}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Book:</Text>
				<Text className={styles.text}>{get(lienItem, "book", "")}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Page:</Text>
				<Text className={styles.text}>{get(lienItem, "page", "")}</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Amount Due:</Text>
				<Text className={styles.text}>
					{lienItem.mitigatedAmount && <Text className={styles.dashedText}>{currencyFormat(calculateInterest(lienItem))}</Text>}
					{lienItem.mitigatedAmount ? currencyFormat(lienItem.mitigatedAmount) : currencyFormat(calculateInterest(lienItem))}
				</Text>
			</div>
			<div className={styles.textRow}>
				<Text className={styles.boldText}>Description:</Text>
				<Text className={styles.text}>{get(lienItem, "description", "")}</Text>
			</div>
		</div>
	);
};

export default LienAccordionItem;
