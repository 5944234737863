import React, { useState, useRef } from "react";
import { Container, Button, Grid, Divider, Accordion, Icon } from "semantic-ui-react";
import { isEmpty, map, get, reject } from "lodash";

// Redux
import { connect } from "react-redux";
import { sendMessage, completeNegotiation } from "../../redux/actions/mitigations";

// Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CityHeader from "../../components/CityHeader";
import Text from "../../components/Text";
import Input from "../../components/Input";
import MessageCard from "../../components/MessageCard";
import LienProgressCard from "../../components/LienProgressCard";
import LienAccordionItem from "../../components/LienAccordionItem";

// Constants
import { guidGenerator } from "../../constants/Utils";

// Styles
import styles from "./styles.module.css";

const Mitigation = ({ dispatch, currentMitigation, userId, userName }) => {
	const [text, setText] = useState("");
	const [file, setFile] = useState(null);
	const [url, setUrl] = useState("");
	const [activeLienIndex, setActiveLienIndex] = useState(0);
	const fileInput = useRef();

	const handleInput = (e) => {
		const { name, value } = e.target;
		switch (name) {
			case "text":
				setText(value);
				break;
			default:
				break;
		}
	};

	const handleEnter = (e) => {
		const { key } = e;
		if (key === "Enter") {
			e.preventDefault();
			handleSend();
		}
	};

	const handleCounter = (messageId, lienId, amount) => {
		dispatch(completeNegotiation(messageId, currentMitigation.id, lienId, amount, "Counter", userId));
	};

	const handleAccept = (messageId, lienId, amount) => {
		dispatch(completeNegotiation(messageId, currentMitigation.id, lienId, amount, "Accept", userId));
	};

	const handleSend = () => {
		if (text !== "" || url !== "") {
			const message = {
				id: guidGenerator(),
				mitigationId: currentMitigation.id,
				type: "Message",
				url,
				timestamp: Date.now(),
				text,
				senderId: userId,
				sender: { id: userId, name: userName, type: "Web" },
				lienId: null,
			};
			dispatch(sendMessage(message));
			setText("");
			setFile(null);
			setUrl("");
		}
	};

	const handleActiveIndex = (index) => {
		if (activeLienIndex === index) {
			setActiveLienIndex(-1);
		} else {
			setActiveLienIndex(index);
		}
	};

	const handleFileInput = async (e) => {
		const fileFromInput = e.target.files[0];
		const base64File = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				return resolve(reader.result);
			};
			reader.readAsDataURL(fileFromInput);
		});
		setUrl(base64File);
		setFile(fileFromInput);
		fileInput.current.value = null;
	};

	const handleAttachClick = () => {
		fileInput.current.click();
	};

	const clearFile = () => {
		setFile(null);
		setUrl("");
		fileInput.current.value = null;
	};

	const renderLienProgress = (array) => {
		if (isEmpty(array)) return null;
		const lienProgressCards = map(array, (item, index) => {
			const isLast = index === array.length - 1;
			return (
				<React.Fragment key={index}>
					<LienProgressCard item={item} />
					{!isLast && <Divider />}
				</React.Fragment>
			);
		});
		return lienProgressCards;
	};

	const renderLienInfo = (array) => {
		if (isEmpty(array)) return null;
		const liens = map(array, (item, index) => {
			const isLast = index === array.length - 1;
			return (
				<React.Fragment key={index}>
					<Accordion.Title
						className={isLast ? styles.lastShareAccordion : styles.shareAccordion}
						active={activeLienIndex === index}
						onClick={() => handleActiveIndex(index)}>
						{`Case Number: ${item.caseNumber}`}
						<Icon className={styles.shareIcon} name={activeLienIndex === index ? "chevron down" : "chevron right"} />
					</Accordion.Title>
					<Accordion.Content active={activeLienIndex === index}>
						<LienAccordionItem mitigationItem={currentMitigation} lienItem={item} />
					</Accordion.Content>
				</React.Fragment>
			);
		});
		return liens;
	};

	const renderList = (array) => {
		if (isEmpty(array)) return null;
		const filtered = reject(array, (item) => {
			return item.type === "Note";
		});
		const messages = map(filtered, (item, index) => {
			const sentByMe = item.senderId === userId;
			const isLast = index === filtered.length - 1;
			const isSamePlatfrom = get(item, "sender.type", "") === "Web";
			return (
				<React.Fragment key={index}>
					<MessageCard
						item={item}
						status={currentMitigation.status}
						isSamePlatfrom={isSamePlatfrom}
						sentByMe={sentByMe}
						handleAccept={handleAccept}
						handleCounter={handleCounter}
						allowCounter={get(currentMitigation, "city.settings.allowCounterOffer", true)}
					/>
					{!isLast && <Divider />}
				</React.Fragment>
			);
		});
		return messages;
	};

	return (
		<div className={styles.screen}>
			<Header />
			<div className={styles.body}>
				<Container>
					<div className={styles.headerContainer}>
						<Text className={styles.title}>{"Lien Mitigation"}</Text>
					</div>
					<CityHeader cityObj={!isEmpty(currentMitigation) ? currentMitigation.city : null} />
					<Grid className={styles.grid}>
						<Grid.Row columns={3} className={styles.height100}>
							<Grid.Column width={8} className={styles.height100}>
								<Text className={styles.titleText}>{"Client Chat"}</Text>
								<div className={styles.chatBox}>
									<div className={styles.list}>{renderList(currentMitigation.messages)}</div>
									{file !== null && (
										<div className={styles.bottomBar}>
											<Text>Attachment: {file.name}</Text>
											<Icon className={styles.buttonIcon} name="times circle outline" onClick={clearFile} />
										</div>
									)}
									<div className={styles.bottomBar}>
										<Icon className={styles.buttonIcon} name="attach" onClick={handleAttachClick} />
										<input ref={fileInput} className={styles.fileInput} type="file" multiple={false} onChange={handleFileInput} />
										<Input className={styles.input} rounded icon={"chat"} name={"text"} value={text} onChange={handleInput} onKeyPress={handleEnter} />
										<Button className={styles.button} icon={"send"} onClick={handleSend} />
									</div>
								</div>
							</Grid.Column>
							<Grid.Column width={8}>
								<Text className={styles.titleText}>{"Mitigation Progress"}</Text>
								<div className={styles.whiteBox}>{renderLienProgress(currentMitigation.interliens)}</div>
								<Text className={styles.titleText}>{"Liens Information"}</Text>
								<div className={styles.whiteBox}>
									<Accordion fluid>{renderLienInfo(currentMitigation.interliens)}</Accordion>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
			<Footer />
		</div>
	);
};

function mapStateToProps({ user, mitigations }) {
	return { userId: user.id, userName: user.name, currentMitigation: mitigations.currentMitigation };
}

export default connect(mapStateToProps)(Mitigation);
